<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'

definePageMeta({
  key: route => route.path,
  title: 'pages.title.index',
})

const state = useStore()

const route = useRoute()
const router = useRouter()

const { ecommerce } = useAnalytics()

const container = ref<HTMLElement | null>(null)

function scrollToContainer() {
  if (container.value == null)
    return

  container.value.scrollIntoView({ behavior: 'smooth' })
}

const term = useRouteQuery('q', '') // search query
const take = useRouteQuery('take', '40') // pagination
const page = useRouteQuery('page', '1', {
  transform: (v) => {
    const page = parseInt(v)
    return isNaN(page) ? 1 : page
  },
})

const drawers = useDrawers()

function handleNavigationClick() {
  drawers.value.navigation.open = true
}
</script>

<!-- <template>
  <HomepageHero />
  <HomepageNavigation />
  <main>
  </main>

  <div ref="container" class="mx-auto max-w-3xl px4 pb8 lg:max-w-[88rem] lg:px8 md:max-w-4xl md:-m-t-2 sm:px6 sm:pb12">
    <div id="bestsellers" class="mx-auto max-w-3xl lg:max-w-[88rem] lg:px6 md:max-w-4xl sm:px4 sm:pb12 sm:pt2">
      <ProductGrid key="index" :page="page" :take="60" :sort="{ popularityScore: SortOrder.DESC }" @update:page="page = $event" />
    </div>
  </div>
</template>
-->
<template>
  <!-- banner -->
  <div class="bg-cover bg-center bg-no-repeat py-16 bgi-[banner-bg.jpg] md:py-36">
    <div class="mx-auto max-w-5xl flex px-4 py2 lg:max-w-7xl md:py4">
      <div>
        <h1 class="mb-4 text-6xl text-gray-800 font-medium">
          {{ $t('approval_page.banner_header') }}
        </h1>
        <p>
          {{ $t('approval_page.banner_text_l1') }}<br>
          {{ $t('approval_page.banner_text_l2') }}
        </p>
        <div class="mt-12">
          <a
            href="#zaphyraAnchor"
            class="bg-primary border-primary hover:text-primary border border-black rounded-md px-8 py-3 text-black font-medium hover:bg-transparent"
          >
            {{ $t('approval_page.shop_now') }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- ./banner -->
  <div class="mx-auto max-w-5xl flex px-4 pt15 lg:max-w-7xl">
    <div class="grid md:grid-flow-col md:grid-cols-2">
      <div id="zaphyraAnchor" class="bg-white">
        <div class="relative mx-auto max-w-7xl px-4 lg:px-8 sm:px-6">
          <div class="mx-auto max-w-2xl lg:max-w-4xl">
            <h2 class="text-3xl text-gray-900 font-bold tracking-tight sm:text-4xl">
              {{ $t('approval_page.sofa_zephyra') }}
            </h2>
            <p class="mt-4 text-gray-500">
              {{ $t('approval_page.sofa_zephyra_desc') }}
            </p>
          </div>
          <div class="flex items-center flex-gap-2 pt5">
            <a class="rounded-md bg-primary-2 p-2 text-white hover:text-white" href="/products/232684/sofa-zephyra">
              {{ $t('approval_page.show_more') }}
            </a>
            <div class="price">
              <Price :regular="207900" />
            </div>
          </div>
          <div class="list ml4 pt5">
            <ul class="list-disc text-gray-600">
              <li>
                {{ $t('approval_page.list_1') }}
              </li>
              <li>
                {{ $t('approval_page.list_2') }}
              </li>
              <li>
                {{ $t('approval_page.list_3') }}
              </li>
              <li>
                {{ $t('approval_page.list_4') }}
              </li>
            </ul>
          </div>
          <dl
            class="grid grid-cols-1 mx-auto mt-8 max-w-2xl gap-x-6 gap-y-5 lg:grid-cols-3 sm:grid-cols-2 lg:max-w-none lg:gap-x-8"
          >
            <div class="border-t border-gray-200 pt-4">
              <dt class="text-gray-800 font-medium">
                {{ $t('approval_page.banner_1_title') }}
              </dt>
              <dd class="mt-2 text-sm text-gray-500">
                {{ $t('approval_page.banner_1_desc') }}
              </dd>
            </div>
            <div class="border-t border-gray-200 pt-4">
              <dt class="text-gray-900 font-medium">
                {{ $t('approval_page.banner_2_title') }}
              </dt>
              <dd class="mt-2 text-sm text-gray-500">
                {{ $t('approval_page.banner_2_desc') }}
              </dd>
            </div>
            <div class="border-t border-gray-200 pt-4">
              <dt class="text-gray-900 font-medium">
                {{ $t('approval_page.banner_3_title') }}
              </dt>
              <dd class="mt-2 text-sm text-gray-500">
                {{ $t('approval_page.banner_3_desc') }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <img src="/products/front.jpg" alt="Front">
    </div>
  </div>
  <div class="mx-auto max-w-5xl flex px-4 lg:max-w-7xl">
    <div class="grid grid-flow-col grid-cols-3">
      <div>
        <img src="/products/p2.jpg" alt="1">
      </div>
      <div>
        <img src="/products/p3.jpg" alt="2">
      </div>
      <div>
        <img src="/products/p4.jpg" alt="3">
      </div>
    </div>
  </div>
  <section aria-labelledby="collections-heading" class="mt10 bg-gray-100">
    <div class="mx-auto max-w-7xl px-4 lg:px-8 sm:px-6">
      <div class="mx-auto max-w-2xl py-16 lg:max-w-none">
        <div class="mt-6 lg:grid lg:grid-cols-3 lg:gap-x-6 space-y-12 lg:space-y-0">
          <div class="group relative">
            <div
              class="sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 relative h-80 w-full overflow-hidden rounded-lg bg-white sm:h-64"
            >
              <img
                src="/products/interior_1.png"
                alt="Interior design 1"
                class="h-full w-full object-cover object-center"
              >
            </div>
          </div>
          <div class="group relative">
            <div
              class="sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 relative h-80 w-full overflow-hidden rounded-lg bg-white sm:h-64"
            >
              <img
                src="/products/interior_2.png"
                alt="Interior design 2"
                class="h-full w-full object-cover object-center"
              >
            </div>
          </div>
          <div class="group relative">
            <div
              class="sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 relative h-80 w-full overflow-hidden rounded-lg bg-white sm:h-64"
            >
              <img
                src="/products/interior_3.png"
                alt="Interior design 3"
                class="h-full w-full object-cover object-center"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section aria-labelledby="collections-heading" class="mt10">
    <div class="mx-auto max-w-7xl px-4 lg:px-8 sm:px-6">
      <h2 class="text-2xl text-gray-900 font-bold tracking-tight">
        {{ $t('approval_page.other_products') }}
      </h2>
      <div class="mx-auto max-w-2xl py-4 lg:max-w-none">
        <ProductGrid />
      </div>
    </div>
  </section>
</template>
